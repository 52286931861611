$image-path: '/images/';
$font-path: '/fonts/';

/*
 * Layout Grid System base font size
 * @see https://www.figma.com/file/G3DyCNSzEIbszR4pdOR7nw/Design-Documentation?node-id=192%3A5620
*/

html {
  font-size: 14px;
  margin: 0;
}

body {
  margin: 0;
}

@media (min-width: 1550px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1921px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 3008px) {
  html {
    font-size: 20px;
  }
}
